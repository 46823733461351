@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

html {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-height: 100%;
    @apply font-sans;
}

code {
    @apply font-mono;
}

#root {
    height: 100%;
    min-height: 100%;
}
